import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AiFillFile, AiOutlineFileText, AiOutlineGlobal,AiOutlineQuestionCircle, AiOutlineDatabase } from 'react-icons/ai';

export const TextUploadPage = () => {
  const [text, setText] = useState(''); // State to hold the input text
  const { uuid } = useParams();


  return (
    <div className="flex w-full shadow-lg fixed h-full flex bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white h-screen p-6 shadow-lg fixed">
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
          <Link
              to={`/files/${uuid}/files`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiFillFile className="mr-2 text-purple-500" /> Files
            </Link>
          </li>
          <li>
            <Link
            to={`/files/${uuid}/text`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineFileText className="mr-2 text-purple-500" /> Text
            </Link>
          </li>
          <li>
            <Link
              to={`/files/${uuid}/website`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineGlobal className="mr-2 text-purple-500" /> Website
            </Link>
          </li>
        </ul>
      </div>

      {/* Main Content for Text Upload */}
      <div className="flex-1 p-6 ml-64">
        <h1 className="text-3xl font-bold mb-4">Text Upload</h1>
        <p className="text-lg mb-4">Enter the text you would like to upload for training your AI Agent.</p>
        
        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          <textarea
            className="w-full h-64 p-4 border border-gray-300 rounded-lg"
            placeholder="Enter text here..."
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};


