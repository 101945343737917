import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

export const Analysis = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("7days");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { chatbotId } = useParams(); // Access chatbotId from URL parameters

  // Theme colors
  const themes = {
    light: {
      background: "bg-gradient-to-br from-slate-50 to-gray-100 h-screen w-full",
     cardBg: "bg-white",
      text: "text-gray-800",
      subtext: "text-gray-500",
      border: "border-gray-200",
      highlight: "bg-indigo-600",
      chartColors: ["#4F46E5", "#EC4899", "#10B981", "#F59E0B"],
    },
    dark: {
      background: "bg-gradient-to-br from-gray-900 to-slate-800",
      cardBg: "bg-gray-800",
      text: "text-gray-100",
      subtext: "text-gray-400",
      border: "border-gray-700",
      highlight: "bg-purple-600",
      chartColors: ["#818CF8", "#F472B6", "#34D399", "#FBBF24"],
    },
  };

  const currentTheme = isDarkMode ? themes.dark : themes.light;

  // Sample data
  const userActivityData = [
    { date: "1 Nov", users: 120, messages: 450, satisfaction: 85 },
    { date: "2 Nov", users: 150, messages: 520, satisfaction: 88 },
    { date: "3 Nov", users: 180, messages: 600, satisfaction: 92 },
    { date: "4 Nov", users: 200, messages: 750, satisfaction: 87 },
    { date: "5 Nov", users: 250, messages: 900, satisfaction: 90 },
  ];

  const chatbotPerformanceData = [
    { name: "Successful", value: 850 },
    { name: "Failed", value: 150 },
    { name: "Pending", value: 200 },
  ];

  const topQueriesData = [
    { query: "How to get started?", count: 245 },
    { query: "Pricing plans", count: 190 },
    { query: "Technical support", count: 170 },
    { query: "Account setup", count: 150 },
    { query: "Integration help", count: 130 },
  ];

  return (
    <div
      className={`min-h-screen ${currentTheme.background} transition-colors duration-300`}
    >
      {/* Updated Navbar */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-4">
        <ul className="flex justify-around px-[20%]">
          {/* Playground Tab */}
          <li className="text-white font-bold cursor-pointer">
            <NavLink
              to={`/agent/${chatbotId}`}
              className={({ isActive }) =>
                isActive ? "text-yellow-300" : "text-white"
              }
              end
            >
              Playground
            </NavLink>
          </li>

          {/* Dynamic Tabs */}
          {["Activity", "Analysis", "Sources", "Connect", "Settings"].map(
            (section) => (
              <li key={section} className="text-white font-bold cursor-pointer">
                <NavLink
                  to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                  className={({ isActive }) =>
                    isActive ? "text-yellow-300" : "text-white"
                  }
                  end
                >
                  {section}
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>

<div>
      {/* Main Content */}
      <div className="pt-24 px-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className={`text-3xl font-bold ${currentTheme.text} mb-2`}>
              Analytics Dashboard
            </h1>
            <p className={`${currentTheme.subtext}`}>
              Track your chatbot's performance and user engagement
            </p>
          </div>
          <div className="flex gap-4">
            <select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
              className={`${currentTheme.cardBg} border ${currentTheme.border} rounded-lg px-4 py-2 ${currentTheme.text} focus:outline-none focus:ring-2 focus:ring-purple-500`}
            >
              <option value="7days">Last 7 Days</option>
              <option value="30days">Last 30 Days</option>
              <option value="90days">Last 90 Days</option>
            </select>
            <button className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-6 py-2 rounded-lg hover:from-purple-700 hover:to-pink-700 transition duration-300 shadow-lg">
              Export Report
            </button>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-4 gap-6 mb-8">
          {[
            {
              title: "Total Users",
              value: "1,234",
              change: "+12%",
              icon: "👥",
            },
            { title: "Active Chats", value: "456", change: "+8%", icon: "💬" },
            { title: "Response Rate", value: "95%", change: "+3%", icon: "⚡" },
            {
              title: "Avg. Response Time",
              value: "45s",
              change: "-10%",
              icon: "⏱️",
            },
          ].map((stat, index) => (
            <div
              key={index}
              className={`${currentTheme.cardBg} p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border ${currentTheme.border}`}
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className={`${currentTheme.subtext} text-sm font-medium`}>
                  {stat.title}
                </h3>
                <span className="text-2xl">{stat.icon}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className={`text-2xl font-bold ${currentTheme.text}`}>
                  {stat.value}
                </span>
                <span
                  className={`text-sm font-medium ${
                    stat.change.startsWith("+")
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {stat.change}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-2 gap-6 mb-8">
          {/* User Activity Chart */}
          <div
            className={`${currentTheme.cardBg} p-6 rounded-xl shadow-lg border ${currentTheme.border}`}
          >
            <h2 className={`text-xl font-bold ${currentTheme.text} mb-4`}>
              User Activity
            </h2>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={userActivityData}>
                <defs>
                  <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={currentTheme.chartColors[0]}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={currentTheme.chartColors[0]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke={isDarkMode ? "#374151" : "#E5E7EB"}
                />
                <XAxis dataKey="date" stroke={currentTheme.subtext} />
                <YAxis stroke={currentTheme.subtext} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: currentTheme.cardBg,
                    borderColor: currentTheme.border,
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="users"
                  stroke={currentTheme.chartColors[0]}
                  fillOpacity={1}
                  fill="url(#colorUsers)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          {/* Chatbot Performance Chart */}
          <div
            className={`${currentTheme.cardBg} p-6 rounded-xl shadow-lg border ${currentTheme.border}`}
          >
            <h2 className={`text-xl font-bold ${currentTheme.text} mb-4`}>
              Agent Performance
            </h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chatbotPerformanceData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {chatbotPerformanceData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={currentTheme.chartColors[index]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: currentTheme.cardBg,
                    borderColor: currentTheme.border,
                  }}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Bottom Chart */}
        <div
          className={`${currentTheme.cardBg} p-6 rounded-xl shadow-lg border ${currentTheme.border} mb-8`}
        >
          <h2 className={`text-xl font-bold ${currentTheme.text} mb-4`}>
            Top Queries
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={topQueriesData}>
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={isDarkMode ? "#374151" : "#E5E7EB"}
              />
              <XAxis dataKey="query" stroke={currentTheme.subtext} />
              <YAxis stroke={currentTheme.subtext} />
              <Tooltip
                contentStyle={{
                  backgroundColor: currentTheme.cardBg,
                  borderColor: currentTheme.border,
                }}
              />
              <Bar dataKey="count">
                {topQueriesData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      currentTheme.chartColors[
                        index % currentTheme.chartColors.length
                      ]
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>
    </div>
  );
};
