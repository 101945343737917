import React, { useState, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'; // Corrected import
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage('userId');
  console.log(`Retrieved token:`,userId);


  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    userId: "",
    address: "",
    age: "",
    email: "",
    currentPlace: "",
    purpose: "",
    websiteLink: "",
  });
  

  const token = FetchDataFromLocalStorage('token');
if (!token) {
  console.log("Token not found. Please log in again.");
} else {
  console.log(`Retrieved token: ${token}`);
}

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/customers/${userId}`, {
          // params: { userId: userId },
          headers: {
            Authorization: `Bearer ${token}`, // Corrected the header syntax
          },
        });
        console.log(response,"response")

        setProfile((prevProfile) => ({
          ...prevProfile,
          ...response.data.customer,
        }));
      } catch (error) {
        console.error("Error fetching chatbot profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const handleEdit = () => {
    navigate("/edit-profile"); // Navigate to CustomerForm page
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-600 to-blue-600">
      <div className="w-full max-w-4xl bg-gradient-to-br from-white to-gray-100 rounded-xl shadow-2xl p-8 border border-gray-300">
        <div className="flex flex-col items-center">
          <div className="relative w-32 h-32 text-indigo-500">
            <UserCircleIcon className="w-full h-full drop-shadow-lg" />
          </div>
          <h1 className="mt-6 text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 via-pink-500 to-blue-600 drop-shadow-md">
            {`${profile.firstName} ${profile.lastName}`}
          </h1>
          <p className="text-gray-700 mt-2 text-lg italic font-medium">{`User ID: ${profile.userId}`}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
          {/* Address */}
          <div>
            <span className="text-lg font-bold text-purple-700 uppercase">Address</span>
            <div className="flex flex-col p-4 mt-2 border border-purple-300 rounded-lg bg-purple-100 shadow-md">
              <span className="text-xl font-semibold text-gray-900">{profile.address}</span>
            </div>
          </div>

          {/* Age */}
          <div>
            <span className="text-lg font-bold text-indigo-700 uppercase">Age</span>
            <div className="flex flex-col p-4 mt-2 border border-indigo-300 rounded-lg bg-indigo-100 shadow-md">
              <span className="text-xl font-semibold text-gray-900">{profile.age}</span>
            </div>
          </div>

          {/* Email */}
          <div>
            <span className="text-lg font-bold text-green-700 uppercase">Email</span>
            <div className="flex flex-col p-4 mt-2 border border-green-300 rounded-lg bg-green-100 shadow-md">
              <span className="text-xl font-semibold text-gray-900">{profile.email}</span>
            </div>
          </div>

          {/* Current Place */}
          <div>
            <span className="text-lg font-bold text-blue-700 uppercase">Current Place</span>
            <div className="flex flex-col p-4 mt-2 border border-blue-300 rounded-lg bg-blue-100 shadow-md">
              <span className="text-xl font-semibold text-gray-900">{profile.currentPlace}</span>
            </div>
          </div>

          {/* Purpose */}
          <div>
            <span className="text-lg font-bold text-pink-700 uppercase">Purpose</span>
            <div className="flex flex-col p-4 mt-2 border border-pink-300 rounded-lg bg-pink-100 shadow-md">
              <span className="text-xl font-semibold text-gray-900">{profile.purpose}</span>
            </div>
          </div>

          {/* Website Link */}
          <div className="md:col-span-2">
            <span className="text-lg font-bold text-orange-700 uppercase">Website Link</span>
            <div className="flex flex-col p-4 mt-2 border border-orange-300 rounded-lg bg-orange-100 shadow-md">
              <a
                href={profile.websiteLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xl font-bold text-blue-700 underline hover:text-blue-900 transition"
              >
                {profile.websiteLink}
              </a>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-10">
          <button
            onClick={handleEdit}
            className="px-6 py-3 bg-gradient-to-r from-green-500 via-blue-500 to-purple-500 text-white text-lg font-semibold rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300"
          >
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
