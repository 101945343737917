import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"; 
import aisaXLogo from "../../img/AiAS-X.jpeg"; // AiSA-X logo image
import chaticonn from "../../img/chaticonn.png";
import { ChatBox } from "./Chatbox";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const isAdmin = localStorage.getItem("isAdmin");
  console.log(isAdmin,"isAdmin")

  // Define routes where the Navbar should be hidden
  const hideNavbarRoutes = ["/register", "/login", "/accessdenied", "/notfound", "/chatlink","*" ];
  
  // Check if the current route matches any of the hideNavbarRoutes
  const isNavbarHidden = hideNavbarRoutes.some(route => location.pathname.startsWith(route));

  // If the route is in the hideNavbarRoutes array, return null to not render the navbar
  if (isNavbarHidden) {
    return null;
  }

  const handleIconClick = () => {
    // Navigate to the AiSA-X homepage
    window.location.href = "https://aisa-x.ai/"; 
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    // Redirect to the AiSA-X homepage after logout
    window.location.href = "https://aisa-x.ai/";
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className=" w-full ">
      <div className=" h-16 bg-[#f4f7fd]  text-[#a5a4bc] flex items-center justify-between px-6      border-b-2 ">
        {/* Left-aligned Logo and Text */}
        <div className="flex items-center cursor-pointer" onClick={handleIconClick}>
          <img
            src={aisaXLogo}
            alt="AiSA-X logo"
            className="w-20 h-12 p-2  border-2 border-[white]"
          />
        </div>

        {/* Centered Links */}
        <ul className="flex space-x-8  font-serif">
          <li
            className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/agents") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}
          >
            <Link to="/agents" className="text-sm font-semibold">
              Agents
            </Link>
          </li>
          <li
            className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/usage") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}
          >
            <Link to="/usage" className="text-sm font-bold">
              Usage
            </Link>
          </li>
          <li
            className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/settings") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}
          >
            <Link to="/settings" className="text-sm font-semibold">
              Settings
            </Link>
          </li>
          {isAdmin === "true" && (
            <li className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/admin-only") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}>
              <Link to="/admin-only" className="text-sm">
                AI-Agents
              </Link>
            </li>
          )}

<li
            className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/UserProfile") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}
          >
            <Link to="/UserProfile" className="text-sm font-semibold">
              Profile
            </Link>
          </li>

          <li
            className="hover:bg-red-600 py-2 px-4 font-secondary rounded-lg transition-all duration-300 ease-in-out transform cursor-pointer"
            onClick={handleLogout}
          >
            Logout
          </li>
        </ul>

        {/* Right-aligned Links (Profile, Logout) */}
        {/* <ul className="flex space-x-4 items-center">
          <li
            className={`py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform ${
              isActive("/UserProfile") ? "bg-white text-[#4e46ff] shadow-lg" : "hover:bg-white hover:text-[#4e46ff] hover:scale-105"
            }`}
          >
            <Link to="/UserProfile" className="text-sm font-semibold">
              Profile
            </Link>
          </li>

          <li
            className="hover:bg-red-600 py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform cursor-pointer"
            onClick={handleLogout}
          >
            Logout
          </li>
        </ul> */}
      </div>

      {/* Bottom-right floating chat icon */}
      <div className="fixed bottom-4 right-4">
        <img
          src={chaticonn}
          alt="chat icon"
          className="w-14 h-14 rounded-full border-4 border-[#00bcd4] shadow-2xl cursor-pointer transition-all duration-300 ease-in-out hover:scale-110"
          onClick={toggleChat}
        />
      </div>

      {/* Conditionally render the Chatbox component */}
      {isChatOpen && <ChatBox />}
    </div>
  );
};
