import { useState, useEffect } from 'react'
import {
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaSave,
  FaTimes,
} from 'react-icons/fa' // Added FaTimes for Cancel
import axios from 'axios'
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import AgentsTable from '../AgentsTable'
import { FileUploadPage } from './FileUploadPage'
import { Chat } from './Chat'

export const Chatbots = () => {
  const [chatbots, setChatbots] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [chatbotName, setChatbotName] = useState('')
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false)
  const [selectedChatbot, setSelectedChatbot] = useState(null)
  const [collection, setCollection] = useState(null)
  const [chosenChatbot, setChosenChatbot] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [inputValue, setInputValue] = useState('')

  const [customizationData, setCustomizationData] = useState({
    agentColor: '#000000',
    userColor: '#000000',
    font: 'Arial',
    agentImg: null,
    backgroundColor: '#FFFFFF',
    name: '',
    welcomeMessage: '',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const apiUrl = process.env.REACT_APP_API_URL
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL

  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')

  useEffect(() => {
    const fetchChatbots = async () => {
      setLoading(true)
      try {
        const response = await axios.post(
          `${apiUrl}/getMyChatBot`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Corrected the header syntax
            },
          },
        ) // Fetch chatbots for user

        setChatbots(response.data || [])
      } catch (error) {
        console.error('Error fetching chatbots:', error)
        alert('Failed to fetch chatbots. Please try again.')
      } finally {
        setLoading(false)
      }
    }

    if (token) {
      fetchChatbots()
    } else {
      console.log('Token is missing, cannot fetch chatbots.')
      setLoading(false)
    }
  }, [userId, token, apiUrl])

  const handleCreateChatbot = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/create-chatbot`,
        { name: chatbotName, userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const newChatbot = {
        _id: data.aiAgentId,
        url: data.url,
        name: chatbotName,
        userId: userId,
        createdAt: data.createdAt,
      }

      setChatbots([...chatbots, newChatbot]) // Add the new chatbot to the list
      navigate(`/agent/${data.aiAgentId}`)
      setChatbotName('')
    } catch (error) {
      console.error('Error creating chatbot:', error)
      alert('Failed to create chatbot. Please try again.')
    }
  }

  const handleChatPageNameSubmit = () => {
    handleCreateChatbot()
    setModalOpen(false) // Close the modal
  }

  const handleChatbotNameSubmit = () => {
    if (chatbotName.trim() === '') {
      alert('Please enter a valid AI-Agent name.')
      return
    }
    handleCreateChatbot()
    setModalOpen(false)
  }

  // Open customization modal
  const openCustomizationModal = chatbot => {
    localStorage.setItem("current_uuid", chatbot.uniqueId)
    setSelectedChatbot(chatbot)
    console.log(chatbot,"chatbot")
    setCustomizationData({
      agentId:chatbot._id,
      agentColor: chatbot.agentColor || '#000000',
      userColor: chatbot.userColor || '#000000',
      font: chatbot.font || 'Arial',
      agentImg: null,
      backgroundColor: chatbot.backgroundColor || '#FFFFFF',
      name: chatbot.name || '',
      welcomeMessage: chatbot.welcomeMessage || '',
    })
    setCustomizationModalOpen(true)
  }

  // Handle customization input changes
  const handleCustomizationChange = (field, value) => {
    setCustomizationData(prevData => ({
      ...prevData,
      [field]: value,
    }))
  }

  // Handle file input separately
  const handleImageChange = file => {
    setCustomizationData(prevData => ({
      ...prevData,
      agentImg: file,
    }))
  }

  const handleTraining = async chatbot => {
    try {
      const response = await axios.post(`${modelUrl}/retrain`, {
        user_id: userId,
        agent_id: chatbot.uniqueId,
      })
      console.log(response.data.collection_id, 'response')
      if (response.data && response.data.collection_id) {
        console.log('Training successful:', response.data.message)
        handleUpdateCollectionName(
          chatbot.uniqueId,
          response.data.collection_id,
        )
        setCollection(response.data.collection_id) // Set the collection ID
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      console.error(
        'Error initiating model retraining:',
        error.response?.data?.detail || error.message,
      )
    }
  }

  const handleUpdateCollectionName = async (uniqueId, collectionName) => {
    try {
      const response = await axios.put(`${apiUrl}/update-collection-name`, {
        uniqueId,
        collectionName,
      })

      if (response.data && response.data.updatedAgent) {
        console.log(
          'Collection name updated successfully:',
          response.data.updatedAgent,
        )
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      console.error(
        'Error updating collection name:',
        error.response?.data?.message || error.message,
      )
    }
  }

  const handleChatbotChange = e => {
    const selectedId = e.target.value
    setChosenChatbot(selectedId)
    setDropdownOptions(chatbots[selectedId] || [])
    setInputValue('') // Clear input if the chatbot changes
  }

  // Handle saving customizations
  const handleSaveCustomizations = async () => {
    if (!selectedChatbot) return;
  
    const formData = new FormData();
    formData.append('agentId', selectedChatbot._id);
    formData.append('AgentColor', customizationData.agentColor);
    formData.append('UserColor', customizationData.userColor);
    formData.append('font', customizationData.font);
    formData.append('BackGroundColor', customizationData.backgroundColor);
    formData.append('name', customizationData.name);
    formData.append('welcomeMessage', customizationData.welcomeMessage);
    if (customizationData.agentImg) {
      formData.append('agentImg', customizationData.agentImg);
    }
  
    try {
      // Send formData directly without spreading it
      await axios.post(`${apiUrl}/agent-update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      alert('Customizations saved successfully!');
  
      setChatbots(prevChatbots =>
        prevChatbots.map(cb =>
          cb._id === selectedChatbot._id
            ? {
                ...cb,
                agentColor: customizationData.agentColor,
                userColor: customizationData.userColor,
                font: customizationData.font,
                backgroundColor: customizationData.backgroundColor,
                name: customizationData.name,
                welcomeMessage: customizationData.welcomeMessage, // This should work now
              }
            : cb,
        ),
      );
  
      setCustomizationModalOpen(false);
      setSelectedChatbot(null);
    } catch (error) {
      console.error('Error saving customizations:', error);
      alert('Failed to save customizations. Please try again.');
    }
  };
  
  

  // Handle cancel customizations
  const handleCancelCustomizations = () => {
    setCustomizationModalOpen(false)
    setSelectedChatbot(null)
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentChatbots = chatbots.slice(indexOfFirstItem, indexOfLastItem)

  console.log(chatbots, 'chatbots')

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const totalPages = Math.ceil(chatbots.length / itemsPerPage)

  return (
    <div className="flex flex-col items-center h-100 justify-start py-6 px-4">
      <div className="text-center w-full max-w-6xl">
        <h1 className="text-5xl font-serif mb-4">Choose Your AI Agents</h1>

        {loading ? (
          <div className="text-lg font-semibold text-gray-600">
            Loading AI Agents...
          </div>
        ) : (
          <>
            {/* <h1> Currently selected agent </h1> */}

            {/* <select
              id="chatbotType"
              value={chosenChatbot}
              onChange={handleChatbotChange}
              style={{ display: 'block', margin: '10px 0', width: '100%' }}
            >
              <option value="">Select Agent</option>
              {chatbots.map(bot => (
                <option key={bot._id} value={bot._id}>
                  {bot.name || 'Unnamed Agent'}
                </option>
              ))}
            </select> */}
            <br/>
            <AgentsTable
              currentChatbots={currentChatbots}
              openCustomizationModal={openCustomizationModal}
              handleTraining={handleTraining}
              paginate= {paginate}
              currentPage={currentPage}
              totalPages={totalPages}
            />
            {/* Chatbots Table */}

            {/* Pagination Controls with Arrow Icons */}

            {/* Create New Chatbot Button */}
            <div className="flex justify-center mt-4">
              <button
                onClick={() => setModalOpen(true)}
                className="bg-gray-500 text-white py-2 px-4 font-serif font-bold rounded-md  hover:bg-gray-700 transition-all duration-300 ease-in-out flex items-center"
              >
                {/* <FaPlus className="mr-2" /> */}
                New Agent
              </button>
            </div>


          </>
        )}
        <div>
          {/* <FileUploadPage /> */}
          {/* <Chat /> */}
        </div>

        {/* Create Chatbot Modal */}
        {modalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/2 lg:w-1/3 shadow-lg overflow-y-auto max-h-[80vh]">
              <h2 className="text-xl font-semibold mb-4">
                Create New AI-Agent
              </h2>
              <input
                type="text"
                value={chatbotName}
                onChange={e => setChatbotName(e.target.value)}
                placeholder="Enter AI-Agent name"
                className="w-full px-4 py-2 border rounded-md mb-4"
              />
              <div className="flex justify-center space-x-2">
                <button
                  onClick={() => setModalOpen(false)}
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={handleChatPageNameSubmit}
                  className="bg-purple-600 text-white py-2 px-4 rounded-lg"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Customization Modal */}
        {customizationModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/2 lg:w-1/3 shadow-lg overflow-y-auto max-h-[80vh]">
              <h2 className="text-xl font-semibold mb-4">
                Customize AI-Agent: {selectedChatbot.name}
              </h2>

              {/* Customization Fields */}
              <div className="grid grid-cols-1 gap-6 mb-6">
                {/* Grouping Agent Color, User Color, and Background Color in a flex row */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                  <div className="flex flex-col">
                    <label className="text-gray-700 mb-2">Agent Color:</label>
                    <input
                      type="color"
                      value={customizationData.agentColor}
                      onChange={e =>
                        handleCustomizationChange('agentColor', e.target.value)
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-gray-700 mb-2">User Color:</label>
                    <input
                      type="color"
                      value={customizationData.userColor}
                      onChange={e =>
                        handleCustomizationChange('userColor', e.target.value)
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-gray-700 mb-2">
                      Background Color:
                    </label>
                    <input
                      type="color"
                      value={customizationData.backgroundColor}
                      onChange={e =>
                        handleCustomizationChange(
                          'backgroundColor',
                          e.target.value,
                        )
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                {/* Font Selector */}
                <div className="flex flex-col">
                  <label className="block text-gray-700 mb-2">Font:</label>
                  <select
                    value={customizationData.font}
                    onChange={e =>
                      handleCustomizationChange('font', e.target.value)
                    }
                    className="border px-4 py-2 rounded-md w-full"
                  >
                    <option value="Arial">Arial</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Courier New">Courier New</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Impact">Impact</option>
                  </select>
                </div>

                {/* Agent Image */}
                <div className="flex flex-col">
                  <label className="block text-gray-700 mb-2">
                    Agent Image:
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={e => handleImageChange(e.target.files[0])}
                    className="border px-4 py-2 rounded-md w-full"
                  />
                  {customizationData.agentImg ? (
                    <img
                      src={URL.createObjectURL(customizationData.agentImg)}
                      alt="Agent Preview"
                      className="mt-2 w-24 h-24 object-cover rounded"
                    />
                  ) : selectedChatbot.agentImg ? (
                    <img
                      src={selectedChatbot.agentImg}
                      alt="Agent"
                      className="mt-2 w-24 h-24 object-cover rounded"
                    />
                  ) : null}
                </div>

                {/* Agent Name */}
                <div className="flex flex-col">
                  <label className="block text-gray-700 mb-2">
                    Agent Name:
                  </label>
                  <input
                    type="text"
                    value={customizationData.name}
                    onChange={e =>
                      handleCustomizationChange('name', e.target.value)
                    }
                    className="border px-4 py-2 rounded-md w-full"
                    placeholder="Enter Agent Name"
                  />
                </div>

                {/* Welcome Message */}
                <div className="flex flex-col">
                  <label className="block text-gray-700 mb-2">
                    Welcome Message:
                  </label>
                  <textarea
                    value={customizationData.welcomeMessage}
                    onChange={e =>
                      handleCustomizationChange(
                        'welcomeMessage',
                        e.target.value,
                      )
                    }
                    className="border px-4 py-2 rounded-md w-full"
                    rows="3"
                    placeholder="Enter welcome message"
                  />
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex justify-between">
                {/* Cancel Button */}
                <button
                  onClick={handleCancelCustomizations}
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  <FaTimes className="mr-2" /> Cancel
                </button>

                {/* Save Button */}
                <button
                  onClick={handleSaveCustomizations}
                  className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700"
                >
                  <FaSave className="mr-2" /> Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
