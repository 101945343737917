import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the DatePicker CSS

export const Usage = () => {
  // Dynamic usage data (you can fetch or update this from an API)
  const [usageHistory, setUsageHistory] = useState([
    { date: '2024-10-01', time: '10:00 AM', chatbot: 'AI Agent A', creditsUsed: 2 },
    { date: '2024-10-05', time: '2:00 PM', chatbot: 'AI Agent A', creditsUsed: 1 },
    { date: '2024-10-10', time: '11:30 AM', chatbot: 'AI Agent B', creditsUsed: 2 },
  ]);

  // States to manage start and end dates for the range picker
  const [startDate, setStartDate] = useState(new Date('2024-10-01'));
  const [endDate, setEndDate] = useState(new Date('2024-10-23'));
  const [showDatePicker, setShowDatePicker] = useState(false); // Toggle for date picker visibility

  // Function to handle date range change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-3xl font-bold mb-4">Usage Information</h1>

      {/* General Usage Info */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4">All AI Agent</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            {/* Clickable Date Range */}
            <p
              className="text-lg font-semibold cursor-pointer"
              onClick={() => setShowDatePicker(!showDatePicker)} // Toggle DatePicker visibility
            >
              {startDate.toISOString().slice(0, 10)} ~ {endDate ? endDate.toISOString().slice(0, 10) : '...'}
            </p>
            {showDatePicker && (
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            )}
          </div>
          <div>
            <p className="text-lg font-semibold text-right">
              Credits used: <span className="text-purple-600">5 / 20</span>
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold">
            AI Agent used: <span className="text-purple-600">1 / 1</span>
            </p>
          </div>
        </div>
      </div>

      {/* Calendar-Like Usage History */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4">Usage History (Calendar View)</h2>
        <div className="grid grid-cols-3 gap-4 text-center">
          {usageHistory.map((usage, index) => (
            <div key={index} className="border p-2 rounded-lg">
              <p className="font-semibold">{usage.date}</p>
              <p className="text-sm text-gray-600">{usage.time}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Credits used per chatbot */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4">Credits Used per AI Agent</h2>
        <p>Here you can see the credits used by each AI Agent.</p>
        {/* Example usage */}
        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">Time</th>
              <th className="px-4 py-2 text-left">AI Agent Name</th>
              <th className="px-4 py-2 text-left">Credits Used</th>
            </tr>
          </thead>
          <tbody>
            {usageHistory.map((usage, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{usage.date}</td>
                <td className="border px-4 py-2">{usage.time}</td>
                <td className="border px-4 py-2">{usage.chatbot}</td>
                <td className="border px-4 py-2">{usage.creditsUsed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Export options */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Export Usage Data</h2>
        <p>Export your usage data in various formats:</p>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-2 hover:bg-blue-600">Download CSV</button>
        <button className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 ml-4 hover:bg-green-600">Download Excel</button>
        <button className="bg-gray-500 text-white py-2 px-4 rounded-lg mt-2 ml-4 hover:bg-gray-600">Download JSON</button>
      </div>
    </div>
  );
};


