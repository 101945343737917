import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AiFillFile, AiOutlineFileText, AiOutlineGlobal, AiOutlineQuestionCircle, AiOutlineDatabase, AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';

export const WebsiteUploadPage = () => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [includedLinks, setIncludedLinks] = useState([]);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);

  const { uuid } = useParams(); // Access chatbotId from URL params


  // Add website URL to the list of included links
  const handleAddWebsite = () => {
    if (websiteUrl) {
      setIncludedLinks([...includedLinks, websiteUrl]);
      setWebsiteUrl('');
    }
  };

  // Add sitemap URL and simulate fetching links
  const handleAddSitemap = () => {
    if (sitemapUrl) {
      const mockLinks = [`${sitemapUrl}/page1`, `${sitemapUrl}/page2`, `${sitemapUrl}/page3`];
      setIncludedLinks([...includedLinks, ...mockLinks]);
      setSitemapUrl('');
    }
  };

  // Toggle delete options visibility
  const toggleDeleteOptions = () => setShowDeleteOptions(!showDeleteOptions);

  // Delete a specific link
  const handleDeleteLink = (index) => {
    const updatedLinks = includedLinks.filter((_, i) => i !== index);
    setIncludedLinks(updatedLinks);
  };

  return (
    <div className="flex w-full shadow-lg fixed h-full flex bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white h-screen p-6 shadow-lg fixed">
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
          <Link
              to={`/files/${uuid}/files`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiFillFile className="mr-2 text-purple-500" /> Files
            </Link>
          </li>
          <li>
            <Link
            to={`/files/${uuid}/text`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineFileText className="mr-2 text-purple-500" /> Text
            </Link>
          </li>
          <li>
            <Link
              to={`/files/${uuid}/website`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineGlobal className="mr-2 text-purple-500" /> Website
            </Link>
          </li>
        </ul>
      </div>

      {/* Main Content Area for Website Upload */}
      <div className="flex-1 p-6 ml-64">
        <h1 className="text-3xl font-bold mb-4">Website Data Source</h1>
        <p className="text-lg mb-4">Add website URLs or sitemaps to use as data sources for training your AI Agent.</p>

        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          {/* Website URL Input */}
          <div className="border p-4 rounded-lg mb-4">
            <h2 className="text-2xl font-semibold mb-4">Crawl Website</h2>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-2"
              placeholder="Enter website URL (e.g., https://www.example.com)"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
            <button
              className="bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition mt-2"
              onClick={handleAddWebsite}
            >
              Crawl
            </button>
          </div>

         
          {/* Included Links Section */}
          <div className="border p-4 rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Included Links</h2>
              <button onClick={toggleDeleteOptions} className="text-red-500 hover:text-red-700 transition">
                <AiOutlinePlus className="text-xl" /> {/* Plus icon for managing delete options */}
              </button>
            </div>

            {includedLinks.length > 0 ? (
              <ul className="list-disc ml-6 space-y-2">
                {includedLinks.map((link, index) => (
                  <li key={index} className="flex items-center justify-between">
                    {link}
                    {showDeleteOptions && (
                      <button
                        onClick={() => handleDeleteLink(index)}
                        className="text-red-500 hover:text-red-700 transition"
                      >
                        <AiOutlineDelete /> {/* Delete icon */}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No links added yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


