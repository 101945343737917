import React, { useEffect, useState } from "react";
// Ensure this path is correct for your project structure

const Loading = () => {
  const messages = [
    "Analyzing data...",
    "Generating insights...",
    "Optimizing response...",
    "Connecting to the neural net...",
    "Loading AI magic..."
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        const currentIndex = messages.indexOf(prevMessage);
        return messages[(currentIndex + 1) % messages.length];
      });
    }, 2000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{ backgroundColor: '#1b2631' }} // Dark background color
    >
      {/* AI Robot Loading GIF */}
      <img
      // Using the imported GIF correctly
        alt="AI Robot Loading"
        className="w-40 sm:w-60 md:w-80 lg:w-96 mb-4" // Responsive width classes
      />
      <p className="mt-4 text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
        {currentMessage}
      </p>
    </div>
  );
};

export default Loading;
