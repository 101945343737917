import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

export default function AgentsTable({
  currentChatbots,
  openCustomizationModal,
  handleTraining,
  paginate,
  currentPage,
  totalPages,
}) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1)
    }
  }

  if (!Array.isArray(currentChatbots)) {
    return <p>No chatbots available.</p> // Or handle the error gracefully
  }

  return (
    <>
      <table className="table-auto border-collapse border border-gray-300 w-full mb-6 bg-[]">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 font-serif text-size-md py-2">
              Agents
            </th>
            <th className="border border-gray-300 font-serif px-4 text-size-md py-2">
            Customize Agent
          </th>
          {/* <th className="border border-gray-300 px-4 text-size-md py-2">
            Retrain Agent
          </th> */}

            <th className="border border-gray-300 font-serif px-4 text-size-md py-2">
              Add Data
            </th>

            <th className="border border-gray-300 font-serif px-4 text-size-md py-2">
              Play
            </th>
          </tr>
        </thead>
        <tbody>
          {currentChatbots.map((chatbot, index) => (
            <tr
              key={index}
              className="hover:bg-gray-100 transition duration-200"
            >
              <td className="border border-gray-300 px-4 py-2 text-center">
                {/* <a
                  href={
                    chatbot.collectionName
                      ? `agent/${chatbot.collectionName}`
                      : chatbot.url
                  }
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  className="text-gray-500 underline"
                > */}
                  {chatbot.name ? chatbot.name : '-'} 💬
                {/* </a> */}
              </td>

              <td className="border border-gray-300 px-4 py-2 flex justify-center">
              <p
                onClick={() => openCustomizationModal(chatbot)}
                className=" cursor-pointer  flex items-center"
              >
                Customize
              </p>
            </td>

            {/* <td className="border border-gray-300 px-4 py-2 text-start">
              <button
                onClick={() => handleTraining(chatbot)}
                className="bg-gray-500 text-white py-1 px-3 rounded-lg hover:bg-yellow-600 flex items-center"
              >
                train
              </button>
            </td> */}

              <td className="border border-gray-300 px-4 py-2 text-center">
                {/* <button
                onClick={() => handleTraining(chatbot)}
                className="bg-gray-500 text-white py-1 px-3 rounded-lg hover:bg-yellow-600 flex items-center"
              > */}
                <a
                  href={`files/${chatbot.uniqueId}/sources`}
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  className="text-gray-500 underline "
                >
                  +
                </a>

                {/* </button> */}
              </td>

              <td className="border border-gray-300 px-4 py-2 text-center">
                <a
                  onClick={() => localStorage.setItem("current_uuid", chatbot.uniqueId)}
                  href={
                    chatbot.collectionName
                      ? `agent/${chatbot.collectionName}`
                      : `files/${chatbot.uniqueId}/sources`
                  }
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  className="text-gray-500 underline"
                >
                  {chatbot.collectionName ? 'Click to play' : 'Train data '}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-12">
        {/* Previous button with left arrow */}
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-white rounded-md hover:bg-gray-500 disabled:opacity-50 flex items-center"
        >
          <FaArrowLeft /> {/* Left arrow icon */}
        </button>

        {/* Next button with right arrow */}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 text-white rounded-md hover:bg-gray-500 disabled:opacity-50 flex items-center"
        >
          <FaArrowRight /> {/* Right arrow icon */}
        </button>
      </div>
    </>
  )
}
