import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaUser, FaEnvelope, FaLink } from "react-icons/fa";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

const Table = () => {
  const [chatbots, setChatbots] = useState([]); // Store chatbots data
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Define items per page

  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage("userId");

  const token = FetchDataFromLocalStorage('authToken');
if (!token) {
  console.log("Token not found. Please log in again.");
} else {
  console.log(`Retrieved token: ${token}`);
}

  useEffect(() => {
    const fetchChatbots = async () => {
      try {
        const response = await axios.post(`${apiUrl}/getALLChatBot`, { userId },
          {
            // params: { userId: userId },
            headers: {
              Authorization: `Bearer ${token}`, // Corrected the header syntax
            },
          }
        ); // Fetch chatbots for the user
        setChatbots(response.data || []); // Set chatbots data
      } catch (error) {
        console.error("Error fetching chatbots:", error);
      }
    };

    fetchChatbots();
  }, [userId, apiUrl]);

  // Filter data based on search input
  const filteredData = chatbots.filter((bot) => {
    const { username, email } = bot.userId;
    const { name, url } = bot;

    return (
      username?.toLowerCase().includes(search.toLowerCase()) ||
      email?.toLowerCase().includes(search.toLowerCase()) ||
      name?.toLowerCase().includes(search.toLowerCase()) ||
      url?.toLowerCase().includes(search.toLowerCase())
    );
  });

  // Paginate data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Reset scroll position to the top whenever page changes
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to the top
  }, [currentPage]);

  return (
    <div className="p-5 bg-cover bg-center">
      <h1 className="text-3xl font-bold text-white mb-6 text-center">AI Agents Table</h1>

      {/* Search Filter */}
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by username, email, name, or URL..."
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 mr-4"
        />
      </div>

      {/* Responsive Table */}
      <div className="overflow-x-auto shadow-xl rounded-lg bg-white">
        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border border-gray-300 px-4 py-2">No</th>
              <th className="border border-gray-300 px-4 py-2">
                <FaUser className="inline-block mr-2" />
                Username
              </th>
              <th className="border border-gray-300 px-4 py-2">
                <FaEnvelope className="inline-block mr-2" />
                Email
              </th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">
                <FaLink className="inline-block mr-2" />
                URL
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((bot, index) => (
                <tr key={bot._id} className="text-center hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{startIndex + index + 1}</td>
                  <td className="border border-gray-300 px-4 py-2">{bot.userId.username}</td>
                  <td className="border border-gray-300 px-4 py-2">{bot.userId.email}</td>
                  <td className="border border-gray-300 px-4 py-2">{bot.name}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <a
                      href={bot.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {bot.url}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="text-center text-gray-500 border border-gray-300 px-4 py-4"
                >
                  No matching records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 flex items-center"
        >
          <FaArrowLeft />
        </button>
        <span className="text-white">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 flex items-center"
        >
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Table;
