// src/components/Chat.js
import React, { useState, useEffect } from "react";

import { FileUploadPage } from "./FileUploadPage";
import ChatComponent from "./ChatComponent";
import { NavLink, useParams } from "react-router-dom";

export const Chat = () => {
  const { chatbotId } = useParams();

  return (
    <div>
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-500">
      {/* Top Navbar with Playground as main chat route */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-4">
        <ul className="flex justify-around px-[20%]">
          {/* Playground Tab - Links to main chat route without URL modification */}
          <li className="text-white font-bold cursor-pointer">
            <NavLink
              to={`/agent/${chatbotId}`}
              className={({ isActive }) =>
                isActive ? "text-yellow-300" : "text-white"
              }
              end
            >
              Playground
            </NavLink>
          </li>
          {/* Other Tabs */}
          {[
            "Activity",
            "Analysis",
            "Sources",
            "Connect",
            "Settings",].map(
            (section) => (
              <li key={section} className="text-white font-bold cursor-pointer">
                <NavLink
                  to={`${section.toLowerCase()}`}
                  className={({ isActive }) =>
                    isActive ? "text-yellow-300" : "text-white"
                  }
                  end
                >
                  {section}
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>
{/* <FileUploadPage/>       */}



      <ChatComponent/>

      

      {/* Main Content Area with Space Below Navbar */}
    </div>
    </div>

  );
};


