// Import React and React Router dependencies
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom'

// Import Components
import { Navbar } from './Components/Chatbots/Navbar'
import { Login } from './Pages/Login'
import { MainPage } from './Components/Chatbots/MainPage'
import { Chatbots } from './Components/Chatbots/Chatbot'
import { Usage } from './Components/Chatbots/Usage'
import { Settings } from './Components/Chatbots/Settings'
import { Connect } from './Components/Chatbots/Connect'
import { Chat } from './Components/Chatbots/Chat'
import { Activity } from './Components/Chatbots/Activity'
import { Analysis } from './Components/Chatbots/Analysis'
import { Sources } from './Components/Chatbots/Sources'
import { FileUploadPage } from './Components/Chatbots/FileUploadPage'
import { TextUploadPage } from './Components/Chatbots/TextUploadPage'
import { WebsiteUploadPage } from './Components/Chatbots/WebsiteUploadPage'
import { Logout } from './Components/Chatbots/Logout'
import { Signup } from './Pages/Register'
import Table from './Components/Chatbots/Table'
import { CustomerForm } from './Components/Chatbots/CustomerForm'
import Loading from './Components/Chatbots/Loading'
import UserProfile from './Components/Chatbots/UserProfile'
import { ChatBoxIframe } from './Components/Iframes/ChatboxIframe'
import { FetchDataFromLocalStorage } from './hooks/FetchDataFromLocalStorage'
import { Sources_Websiteupload } from './Components/Chatbots/Sources_Websiteupload'
import { Sources_FileUploadPage } from './Components/Chatbots/Sources_Fileupload'
import { Sourcess_TextUploadPage } from './Components/Chatbots/Sourcess_Textupload'
import {NotFoundPage} from  "./Pages/NotFoundPage"

export * from './constants/apiUrl'
export * from './constants/config'


const ProtectedRoute = ({ children }) => {
  const isAdmin = FetchDataFromLocalStorage('isAdmin') === 'true'
  console.log(isAdmin, 'isAdmin')
  if (!isAdmin) {
    return <Navigate to="/access-denied" replace />
  }
  return children
}

// Access Denied Page Component
const AccessDenied = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>Access Denied</h1>
    <p>You do not have permission to view this page.</p>
  </div>
)

// Example Admin Page Component
const AdminPage = () => (
  <div>
    <h1>Admin Dashboard</h1>
    <p>Welcome, Admin!</p>
  </div>
)

// Main App Component
function App() {
  return (
    
    <Router>
      <MainApp />
    </Router>
  )
}

function MainApp() {
  const location = useLocation()
  const [currentUser, setCurrentUser] = useState('admin')

  function checkIfAdmin() {
    setCurrentUser('admin')
  }

  // Define routes where Navbar should NOT appear
  const hideNavbarRoutes = ["/register", "/login", "/accessdenied" ,"/notfound" , "/agentlink", "/",];
  const isChatRoute = location.pathname.startsWith("/agent/" ,"/notfound","/register", "/login" ,"/files");

  return (
    <div className="App">
     
      {/* Conditionally render Navbar */}
      {!hideNavbarRoutes.includes(location.pathname) && !isChatRoute && (
        <Navbar />
      )}

      <Routes>
        {/* Admin-only route */}
        <Route
          path="/admin-only"
          element={
            <ProtectedRoute>
              <Table /> {/* Your admin-only page */}
            </ProtectedRoute>
          }
        />

        {/* Default landing page */}
        <Route path="/" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/agents" element={<Chatbots />} />

        {/* Chatbot-specific routes */}
        <Route path="usage" element={<Usage />} />
        <Route path="settings" element={<Settings />} />
        <Route path="connect" element={<Connect />} />
        <Route path="/UserProfile" element={<UserProfile />} />
        <Route path="/edit-profile" element={<CustomerForm />} />
        <Route path="files/:uuid/sources" element={<FileUploadPage />} />
          <Route path="files/:uuid/files" element={<FileUploadPage />} />
          <Route path="files/:uuid/text" element={<TextUploadPage />} />
          <Route path="files/:uuid/website" element={<WebsiteUploadPage />} />
        <Route path="agentlink/:chatbotId" element={<ChatBoxIframe />} />


        {/* Nested chatbot routes */}
        <Route path="/agent/:chatbotId" element={<Chat />}>
          <Route path="usage" element={<Usage />} />
          <Route path="settings" element={<Settings />} />
          <Route path="activity" element={<Activity />} />
          <Route path="connect" element={<Connect />} />
          <Route path="analysis" element={<Analysis />} />
          {/* <Route path="sources" element={<Sources />} /> */}
          <Route path="/agent/:chatbotId/sources" element={<Sources_FileUploadPage />} />
          <Route path="/agent/:chatbotId/analysis" element={<Analysis />} />






          {/* <Route
            path="/agent/:chatbotId/website"
            element={<Sources_Websiteupload />}
          />
          <Route
            path="/agent/:chatbotId/files"
            element={<Sources_FileUploadPage />}
          />
          <Route
            path="/agent/:chatbotId/text"
            element={<Sourcess_TextUploadPage />}
          /> */}
        </Route>

        {/* Other independent routes */}
        {/* <Route path="/files/:chatbotId" element={<FileUploadPage />} /> */}

        <Route path="/table" element={<Table />} />
        <Route path="/logout" element={<Logout />} />

        {/* Access Denied Route */}
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/loading" element={<Loading />} /> 
        
        {/* Catch-All Route for Undefined URLs */}
         <Route path="*" element={<NotFoundPage />} />
      
      </Routes>
    </div>
  )
}

export default App
