import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import DownArrowIcon from "../../img/downarrow.png"; // Adjust the path as needed
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

export const ChatBoxIframe = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const { chatbotId } = useParams(); // Retrieve chatbotId from URL parameters
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL;
  const chatBoxRef = useRef(null); // Reference for scrolling to bottom


  // const uuid = searchParams.get("user");

  const userId = FetchDataFromLocalStorage("userId") ;
  const uuid = FetchDataFromLocalStorage("current_uuid") ;





  // Function to fetch the initial message from the backend
  const fetchInitialMessage = async () => {
    if (!modelUrl) {
      console.error("Model URL is not defined.");
      return;
    }
  
    const formData = new FormData();
    formData.append("userId", userId); // Add user ID to the form data
    formData.append("uuid", uuid);
    formData.append("collection_id", chatbotId);
  
    try {
      const response = await fetch(`${modelUrl}/initial_message`, {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log(data.assistant_response, "data");
      if (data && data.assistant_response) {
        typeMessage(data.assistant_response); // Apply typing effect
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching initial message:", error);
      setMessages([
        { text: "Error: Could not load the initial message.", sender: "Bot" },
      ]);
    }
  };

  useEffect(() => {
    fetchInitialMessage();
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]); // Scroll to bottom when messages update

  // Function to send a user message to the backend and get a response
  const sendMessageToBackend = async (userMessage) => {
    try {
      const response = await fetch(`${modelUrl}/query`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ question: userMessage, collection_id:chatbotId
         }),
      });

      const data = await response.json();

      if (data.answer) { 
        typeMessage(data.answer); // Apply typing effect
      } else {
        throw new Error("Invalid response from backend");
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Error: Could not connect to the AGENT.", sender: "Bot" },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const formatTextWithBold = (text) => {
    // Remove unnecessary symbols and format numbered headings as bold
    const cleanedText = text.replace(/[#!*-]/g, ""); // Remove #, *, and - symbols
    return cleanedText.replace(/(\d+\.\s)(.*?)(:)/g, "<strong>$1$2</strong>$3"); // Apply bold formatting to numbered headings
  };

  const handleSendMessage = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: "User" };
      setMessages([...messages, newMessage]);
      setInput("");
      setLoading(true);

      await sendMessageToBackend(input);
    }
  };


  // Helper function to format text with headings and bold formatting
  const formatTextWithHeadingAndBold = (text) => {
    const lines = text.split("\n"); // Split text into lines
    return lines.map((line, index) => {
      if (line.startsWith("#")) {
        // Heading style
        return <h1 key={index} className="font-bold text-xl mb-2">{line.replace(/#/g, "")}</h1>;
      } else if (line.includes("**")) {
        // Bold style
        const parts = line.split("**");
        return (
          <p key={index}>
            {parts.map((part, i) => (i % 2 === 1 ? <strong key={i}>{part}</strong> : part))}
          </p>
        );
      } else {
        // Regular text
        return <p key={index}>{line}</p>;
      }
    });
  };


  
  // Typing animation function with faster message display
  const typeMessage = async (text) => {
    setLoading(true);
    const lines = text.split("\n"); // Split by line for step-by-step effect

    for (const line of lines) {
      const formattedLine = formatTextWithBold(line);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedLine, sender: "Bot" },
      ]);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay between each line
    }

    setLoading(false);
  };

  const handleScrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  return (
    <div
      className="fixed inset-0 flex flex-col p-4"
      style={{
        background: "linear-gradient(to bottom right, #f0f4f8, #d9e2ec)", // Light gray gradient background
        height: "100vh",
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Professional font
      }}
    >
      <div
        ref={chatBoxRef}
        className="flex-grow h-0 overflow-y-auto p-4 bg-white bg-opacity-90 rounded-lg shadow-lg mb-4"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-3 flex ${
              message.sender === "user" ? "justify-end" : "justify-start"
            }`}
          >
           <span
  className={`inline-block p-2 rounded-lg max-w-300px break-words ${
    message.sender === "user"
      ? "bg-blue-400 text-white max-w-md font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif rounded-3xl self-end mr-2"
      : " text-black font-family-inter max-w-screen-lg self-start"
  }`}
>
  {message.text}
</span>
          </div>
        ))}
      </div>
  
      
      <div className="flex mt-auto">
        <div className="flex w-full border-t border-gray-300 rounded-lg bg-gray-100 p-2 shadow-md">
          <input
            type="text"
            className="flex-1 p-3 border-none rounded-lg focus:outline-none"
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
            disabled={loading} // Disable input while waiting for bot response
            style={{
              fontSize: "16px",
              borderRadius: "8px",
              boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
          <button
            onClick={handleSendMessage}
            className="ml-2 py-2 px-6 text-white rounded-lg"
            style={{
              background: "linear-gradient(to right, #0078d4, #0053a6)", // Blue gradient for button
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              fontWeight: "bold",
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
  

};
