import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { AiFillFile, AiOutlineFileText, AiOutlineGlobal } from "react-icons/ai";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

export const Sources_FileUploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentList, setDocumentList] = useState([]); // State to hold fetched document details
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { chatbotId } = useParams();
  const uuid = chatbotId;         // need to replace the naming to uuid  later in routes also     wise 

  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage("userId") || 100; // Retrieve user ID from local storage
  const location = useLocation();

  // Fetch documents when the component mounts
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${apiUrl}/getDocuments/${uuid}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, uuid }), // Object shorthand for userId and uuid
        });
    
        const data = await response.json();
    
        if (response.ok) {
          // Check if data.documents exists and is an array
          const documents = Array.isArray(data?.data?.[`AiAgent ${uuid}`])
            ? data.data[`AiAgent ${uuid}`]
            : [];
          setDocumentList(documents);
        } else {
          console.error("Error fetching documents:", data.message || "Unknown error");
          alert(data.message || "Failed to load documents.");
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        alert("An error occurred while fetching documents.");
      }
    };
    

    fetchDocuments();
  }, [apiUrl, userId, uuid]);


  
  // Function to handle file selection and upload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    const formData = new FormData();
    formData.append("userId", userId); // Add user ID to the form data
    formData.append("uuid", uuid); 
    formData.append("DocumentType", "File"); 


    Array.from(files).forEach((file) => {
      formData.append("documents", file);
    });

    try {
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (response.ok) {
        alert("File uploaded successfully!");
        // setDocumentList((prevList) => [...prevList, ...result.uploadedFiles]); // Assuming API response includes `uploadedFiles`
        location.reload();
      } else {
        alert("Error: " + result.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("File upload failed.");
    }
  };

  // Function to integrate Google Picker API (Google Drive access)


  // Function to integrate Google Picker API (Google Drive access)
  const handleGoogleDriveUpload = () => {
    alert("Google Drive upload feature coming soon!");
  };

  return (
    <div className="flex w-full shadow-lg fixed h-full bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-full md:w-64 bg-white h-full p-6 shadow-lg md:fixed top-0 left-0">
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
            <Link
              to={`/agent/${chatbotId}/files`} // Files link
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiFillFile className="mr-2 text-purple-500" /> Files
            </Link>
          </li>
          <li>
            <Link
              to={`/agent/${chatbotId}/text`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineFileText className="mr-2 text-purple-500" /> Text
            </Link>
          </li>
          <li>
            <Link
              to={`/agent/${chatbotId}/website`}
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <AiOutlineGlobal className="mr-2 text-purple-500" /> Website
            </Link>
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 p-6 md:ml-64 mt-16 md:mt-0">
        <h1 className="text-3xl font-bold mb-6">Upload Files</h1>
        <p className="text-lg mb-6">
          Select files to upload from your device or Google Drive
        </p>

        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          <h2 className="text-2xl font-bold mb-4">Files</h2>
          <p className="mb-4">
            Drag & drop files here, click to select files, or use Google Drive.
          </p>

          {/* File Upload Input */}
          <div className="flex flex-col md:flex-row gap-4">
            <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg cursor-pointer hover:bg-gray-50 relative w-full md:w-1/2">
              <input
                type="file"
                multiple
                onChange={handleFileUpload}
                className="w-full h-full opacity-0 cursor-pointer absolute top-0 left-0"
              />
              <p className="text-center">Click or drag files here to upload</p>
              <p className="text-center text-sm text-gray-500 mt-2">
                Supported File Types: .pdf, .doc, .docx, .txt
              </p>
            </div>

            {/* Google Drive Upload Button */}
            <button
              onClick={handleGoogleDriveUpload}
              className="w-full md:w-1/2 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Upload from Google Drive
            </button>
          </div>

          {/* Display Uploaded Documents */}
          {documentList.length > 0 && (
            <div className="mt-6">
              <h3 className="text-xl font-bold mb-4">Uploaded Documents</h3>
              <ul className="list-disc ml-6">
                {documentList.map((doc, index) => (
                  <li key={index} className="mb-2">
                    {doc.documentName }
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};
