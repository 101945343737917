// src/components/Login.js
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AiOutlineLock } from 'react-icons/ai'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const apiUrl = process.env.REACT_APP_API_URL

  const handleLogin = e => {
    e.preventDefault()
    axios
      .post(`${apiUrl}/login-auth`, { email, password })
      .then(response => {
        console.log(response, 'response') // Debugging response object

        const { message, userId, isAdmin, token, subscriptionStatus } = response.data

        if (message === 'Login successful') {
          localStorage.setItem('userId', userId) // Store user ID
          localStorage.setItem('isAdmin', isAdmin) // Store admin status
          localStorage.setItem('token', token) // Store token
          localStorage.setItem('subscriptionStatus', subscriptionStatus) // Store token


          if (isAdmin) {
            navigate('/admin-only') // Navigate to admin-only page
          } else {
            navigate('/agents') // Navigate to chatbots page
          }
        } else {
          alert(message) // Show an alert if login fails
        }
      })
      .catch(err => {
        console.error('Login error:', err)
        alert('An error occurred during login. Please try again.')
      })
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-purple-400 via-blue-500 to-teal-400 p-6">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105">
        <div className="flex flex-col items-center mb-6">
          <AiOutlineLock className="text-6xl text-blue-500 mb-3" />
          <h2 className="text-3xl font-bold text-gray-800 text-center">
            Generative AI-Powered AI Agent
          </h2>
        </div>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 mt-4 text-white bg-blue-600 rounded-md font-semibold hover:bg-blue-700 transition-colors"
          >
            Sign In
          </button>
        </form>
        <p>Do not have an account?</p>
        <Link
          to="/register"
          className="block w-full text-center mt-2 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-200"
        >
          Register
        </Link>
      </div>
    </div>
  )
}
