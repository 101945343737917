import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser, FaBox } from "react-icons/fa";
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'; 

export const CustomerForm = () => {
  const Navigate = useNavigate(); 
  const apiUrl = process.env.REACT_APP_API_URL;

  const userId1 = FetchDataFromLocalStorage("userId");
  const token = FetchDataFromLocalStorage('token');

  const [formData, setFormData] = useState({
    firstName: "",
    userId: userId1,
    lastName: "",
    address: "",
    age: "",
    email: "",
    currentPlace: "",
    purpose: "",
    websiteLink: "",
  });

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);

    axios.post(`${apiUrl}/api/customers`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Corrected the header syntax
        },
      }
    )
      .then(result => {
        if (result.data.success === true) {
          // FetchDataFromLocalStorage("userId", result.data.userId); // Save user ID to localStorage
          // FetchDataFromLocalStorage("authToken", result.data.authToken); // Save auth token if needed
          Navigate("/userprofile"); // Navigate to UserProfile page after form submission
        } else {
          alert(result.data.message); // Show an alert if form submission fails
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="max-w-xl mx-auto mt-7 p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-center mb-4">
        <FaUser size={30} className="inline mr-2" />
      </div>

      <h2 className="text-xl font-semibold text-center text-black mb-6">Customer Form</h2>

      {statusMessage && (
        <p className="text-green-500 text-center mb-4">{statusMessage}</p>
      )}

      <form onSubmit={handleSubmit}>
        {/* Form Fields */}
        {[
          { label: "First Name", name: "firstName", type: "text" },
          { label: "Last Name", name: "lastName", type: "text" },
          { label: "Address", name: "address", type: "text" },
          { label: "Age", name: "age", type: "number" },
          { label: "Email", name: "email", type: "email" },
          { label: "Current Place", name: "currentPlace", type: "text" },
          { label: "Purpose", name: "purpose", type: "text" },
          { label: "Website Link", name: "websiteLink", type: "url" },
        ].map((field) => (
          <div key={field.name} className="mb-4">
            <label className="block text-sm text-black font-medium mb-2">{field.label}</label>
            <input
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300"
            />
          </div>
        ))}

        <button
          type="submit"
          className="w-full py-2 bg-indigo-600 text-white font-semibold text-lg rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CustomerForm;
