import React, { useState, useRef, useEffect } from "react";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import {
  FaArrowLeft,
  FaFilter,
  FaFileExport,
  FaComments,
  FaUsers,
} from "react-icons/fa";

export const Activity = () => {
  const [activeTab, setActiveTab] = useState("Chat Logs");
  const [exportOptionsVisible, setExportOptionsVisible] = useState(false);
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const { chatbotId } = useParams(); // Access chatbotId from URL params

  const handleBackClick = () => {
    navigate(`/agent/${chatbotId}`);
  };

  const handleExportClick = () => {
    setExportOptionsVisible(!exportOptionsVisible);
  };

  const exportAsJSON = () => {
    console.log("Exporting as JSON...");
  };

  const exportAsPDF = () => {
    console.log("Exporting as PDF...");
  };

  const exportAsCSV = () => {
    console.log("Exporting as CSV...");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setExportOptionsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-500">
      {/* Top Navbar */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-4">
        <ul className="flex justify-around px-[20%]">
          {/* Playground Tab */}
          <li className="text-white font-bold cursor-pointer">
            <NavLink
              to={`/agent/${chatbotId}`}
              className={({ isActive }) =>
                isActive ? "text-yellow-300" : "text-white"
              }
              end
            >
              Playground
            </NavLink>
          </li>

          {/* Dynamic Tabs */}
          {["Activity", "Analysis", "Sources", "Connect", "Settings"].map(
            (section) => (
              <li key={section} className="text-white font-bold cursor-pointer">
                <NavLink
                  to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                  className={({ isActive }) =>
                    isActive ? "text-yellow-300" : "text-white"
                  }
                  end
                >
                  {section}
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>

      {/* Content Section */}
      <div className="flex flex-col items-center justify-center min-h-screen p-4 pt-20">
        <div className="flex h-screen relative absolute top-[10%]">
          <nav className="w-1/4 text-black p-4">
            <div className="flex items-center mb-8">
              <FaArrowLeft
                className="cursor-pointer text-white mr-2"
                onClick={handleBackClick}
              />
              <h2 className="text-2xl font-bold text-white">Activity</h2>
            </div>
            <ul>
              <li
                className={`cursor-pointer text-white p-4 hover:text-gray-200 transition duration-300 rounded-[20px] ${
                  activeTab === "Chat Logs" ? "font-bold" : ""
                }`}
                onClick={() => setActiveTab("Chat Logs")}
              >
                <FaComments className="mr-2" /> Chat Logs
              </li>
              <li
                className={`cursor-pointer text-white p-4 hover:text-gray-200 transition duration-300 rounded-[20px] ${
                  activeTab === "Leads" ? "font-bold" : ""
                }`}
                onClick={() => setActiveTab("Leads")}
              >
                <FaUsers className="mr-2" /> Leads
              </li>
            </ul>
          </nav>

          <div className="w-3/4 p-8">
            <div className="relative p-4">
              <h1 className="text-3xl font-bold text-white">{activeTab}</h1>
              <div className="flex justify-end space-x-4 absolute top-0 right-[10%]">
                <div className="flex items-center cursor-pointer text-white mr-4">
                  <FaFilter className="mr-2" />
                  <h3 className="text-xl font-bold">Filter</h3>
                </div>
                <div className="relative">
                  <div
                    className="flex items-center cursor-pointer text-white"
                    onClick={handleExportClick}
                  >
                    <FaFileExport className="mr-2" />
                    <h3 className="text-xl font-bold">Export</h3>
                  </div>
                </div>
              </div>
              {exportOptionsVisible && (
                <div
                  ref={exportRef}
                  className="absolute top-full mt-2 bg-gray-100 shadow-md p-4 rounded-md w-40 right-[5%]"
                >
                  <ul>
                    <li
                      className="cursor-pointer text-black hover:bg-gray-200 p-2 rounded-md"
                      onClick={exportAsJSON}
                    >
                      JSON
                    </li>
                    <li
                      className="cursor-pointer text-black hover:bg-gray-200 p-2 rounded-md"
                      onClick={exportAsPDF}
                    >
                      PDF
                    </li>
                    <li
                      className="cursor-pointer text-black hover:bg-gray-200 p-2 rounded-md"
                      onClick={exportAsCSV}
                    >
                      CSV
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="flex mt-4">
              <div className="border border-gray-200 rounded-l-lg w-[400px] h-[600px] p-4">
                <p className="bg-violet-500 text-white inline-block p-4 rounded-lg shadow-md">
                  What are the primary colors?
                </p>
              </div>
              <div className="border border-gray-200 rounded-tr-lg w-[500px] h-[600px] p-4">
                <div className="bg-violet-500 text-white inline-block p-4 rounded-lg shadow-md">
                  <p>What are the primary colors?</p>
                </div>
                <div className="flex justify-end">
                  <p className="bg-white text-violet-500 p-4 rounded-lg shadow-md inline-block border mt-4">
                    The primary colors are red, green, blue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
