// Fetch data from localStorage
export function FetchDataFromLocalStorage(key) {
  const storedData = localStorage.getItem(key);
  // console.log(`Found ${key}:`, storedData); // Log the stored value

  if (storedData) {
    return storedData; // Return the raw string (no JSON parsing)
  }

  return null; // Or return a default value if needed
}