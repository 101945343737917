import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  LinkIcon,
  ShareIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/outline";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

export const Connect = () => {
  const [activeSection, setActiveSection] = useState("Embed");
  const { chatbotId } = useParams(); // Access chatbotId from URL params
  const uuid = FetchDataFromLocalStorage("current_uuid") ;
  const userId = FetchDataFromLocalStorage("userId") ;

  
  console.log(chatbotId,"collection_id")


  const menuItems = [
    { name: "Embed", icon: <LinkIcon className="w-5 h-5" />, section: "Embed" },
    { name: "Share", icon: <ShareIcon className="w-5 h-5" />, section: "Share" },
    {
      name: "Integrations",
      icon: <CodeBracketIcon className="w-5 h-5" />,
      section: "Integrations",
    },
  ];

  // Reset scroll position on render or when activeSection changes
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to the top
  }, [activeSection]); // Trigger when activeSection changes



  return (
    <div className="flex w-full shadow-lg fixed h-full  ">
      {/* Sidebar */}
      <div className="w-64 bg-gray-900 text-white p-6 shadow-lg fixed h-full top-0 left-0">
        <h1 className="text-2xl font-bold mb-6 text-purple-200">Connect</h1>
        <ul className="space-y-4">
          {menuItems.map((item) => (
            <li key={item.name}>
              <button
                onClick={() => setActiveSection(item.section)}
                className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-600 transition duration-300 w-full text-left"
              >
                {item.icon}
                <span className="ml-2">{item.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <main className="flex-1 p-6 ml-64 bg-white rounded-tl-lg shadow-inner pt-20 ">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          Agent Integration
        </h1>

        {/* Conditional Rendering Based on Active Section */}
        {activeSection === "Embed" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Embed the AI Agent</h2>
            <p className="mb-4 text-gray-600">
              To add the AI Agent anywhere on your website, add this iframe:
            </p>
            <code className="block p-4 bg-gray-100 rounded-lg text-sm shadow">
              {`<iframe src="https://agents.aisa-x.ai/agentlink/${chatbotId}?user=${userId}&agent=${uuid}" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`}
            </code>


            {/* Chat Bubble Script Section */}
            <h2 className="text-2xl font-semibold mt-8 mb-4">
              Add Chat Bubble
            </h2>
            <p className="mb-4 text-gray-600">
              To add a chat bubble to the bottom right of your website, add
              this script tag to your HTML:
            </p>
            <code className="block p-4 bg-gray-100 rounded-lg text-sm shadow">
              {`<script>
  window.embeddedChatbotConfig = {
    chatbotId: "6vuASiNrQ_VnlWiCTmU-g",
    domain: "www.Aisa-x.co"
  }
</script>
<script
  src="https://www.chatbase.co/embed.min.js"
  chatbotId="6vuASiNrQ_VnlWiCTmU-g"
  domain="www.Aisa-x.co"
  defer>
</script>`}
            </code>
          </section>
        )}
 
        {activeSection === "Share" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Share the AI Agent</h2>
            <p className="text-gray-600">
              Use this link to share the AI Agent with others:
            </p>
            <code className="block p-4 bg-gray-100 rounded-lg text-sm shadow">
              {`https://www.chatbase.co/chatbot/6vuASiNrQ_VnlWiCTmU-g`}
            </code>
          </section>
        )}

        {activeSection === "Integrations" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Integrations</h2>
            <p className="text-gray-600">
              Explore various integrations to add more functionality to your
              AI Agent.
            </p>
          </section>
        )}
      </main>
    </div>
    
  );
};
