import { useEffect, useState } from "react";
import ChatImg from "../../img/Chatingimg.jpg"; // Adjust the path if needed
import { Outlet, useParams, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import axios from "axios";

export default function ChatComponent() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const { chatbotId } = useParams(); // Retrieve chatbotId from URL parameters
    console.log(chatbotId,"chatbotId")
    const location = useLocation();
    const navigate = useNavigate();
    const modelUrl = process.env.REACT_APP_AI_AGENT_URL;
    const apiUrl = process.env.REACT_APP_API_URL;


    const userId = FetchDataFromLocalStorage("userId") || 100; 
    const uuid = FetchDataFromLocalStorage("current_uuid") || 100; 


    const fetchInitialMessage = async () => {
      if (!modelUrl) {
        console.error("Model URL is not defined.");
        return;
      }
    
      // Prepare URL with query parameters
      const params = {
        userId: userId,          // Add user ID as a query parameter
        agentId: chatbotId,      // Add agent ID as a query parameter
        collection_id: chatbotId // Add collection_id as a query parameter
      };
    
      try {
        // Send GET request with query parameters
        const response = await axios.get(`${apiUrl}/initial_message/${uuid}`, { params });
    
        // Check response structure
        if (response.data.success && response.data.agent_Details) {
          const welcomeMessage = response.data.agent_Details.welcomeMessage;
          const name =response.data.agent_Details.name;
          if (welcomeMessage) {
            console.log(welcomeMessage, "data");
            typeMessage(welcomeMessage); 
          } else {
            typeMessage(`hi i am ${name} , How can i assisst you `); 

          }
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching initial message:", error);
        setMessages([
          { text: "Error: Could not load the initial message.", sender: "Bot" },
        ]);
      }
    };
    
    
  
    useEffect(() => {
      fetchInitialMessage();
    }, []);
  
    const sendMessageToBackend = async (userMessage) => {
      try {
        const response = await fetch(`${modelUrl}/query`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ question: userMessage,collection_id:chatbotId
           }),
        });
  
        const data = await response.json();
  
        if (data.answer) { 
          typeMessage(data.answer); // Apply typing effect
        } else {
          throw new Error("Invalid response from backend");
        }
      } catch (error) {
        console.error("Error fetching response:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Error: Could not connect to the AGENT.", sender: "Bot" },
        ]);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSendMessage = async () => {
      if (input.trim()) {
        const newMessage = { text: input, sender: "User" };
        setMessages([...messages, newMessage]);
        setInput("");
        setLoading(true);
  
        await sendMessageToBackend(input);
      }
    };
  
    const handleClick = (section) => {
      navigate(`/${section.toLowerCase()}`);
    };
  
    const typeMessage = async (text) => {
      setLoading(true);
      const lines = text.split("\n"); // Split by line for step-by-step effect
  
      for (const line of lines) {
        const formattedLine = formatTextWithBold(line);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: formattedLine, sender: "Bot" },
        ]);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay between each line
      }
  
      setLoading(false);
    };
  
    const formatTextWithBold = (text) => {
      // Remove unnecessary symbols and format numbered headings as bold
      const cleanedText = text.replace(/[#!*-]/g, ""); // Remove #, *, and - symbols
      return cleanedText.replace(/(\d+\.\s)(.*?)(:)/g, "<strong>$1$2</strong>$3"); // Apply bold formatting to numbered headings
    };

  return (
    <div className="pt-24 z-30 flex w-full flex-col items-center">
    {/* Display chat interface on the main chat route, otherwise render Outlet */}
    {location.pathname === `/agent/${chatbotId}` ? (
      <div className="w-full max-w-md h-full md:h-5/6 p-4 bg-white shadow-xl rounded-lg relative">
        <h1 className="text-2xl font-bold text-center mb-4">
          AI Assistant
        </h1>

        {/* Chat window with background image */}
        <div
          className="h-96 md:h-[400px] overflow-y-auto mb-4 bg-gray-100 p-4 rounded-lg shadow-inner"
          style={{
            backgroundImage: `url(${ChatImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`mb-2 ${
                msg.sender === "User" ? "text-right" : "text-left"
              }`}
            >
              <span
                className={`inline-block p-2 rounded-lg shadow-md ${
                  msg.sender === "User"
                    ? "bg-purple-600 text-white"
                    : "bg-gray-300 text-black"
                }`}
              >
                {msg.text}
              </span>
            </div>
          ))}
          {loading && (
            <div className="text-center text-gray-500">
              Agent is Thinking 💬...
            </div>
          )}
        </div>

        {/* Input area */}
        <div className="absolute bottom-4 left-0 right-0 px-4">
          <div className="flex">
            <input
              type="text"
              className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none"
              placeholder="Type a message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSendMessage();
              }}
              disabled={loading} // Disable input while waiting for bot response
            />
            <button
              onClick={handleSendMessage}
              className="bg-purple-600 text-white py-2 px-4 rounded-r-lg hover:bg-purple-700 transition duration-300"
              disabled={loading} // Disable button while waiting for bot response
            >
              Send
            </button>
          </div>
        </div>
      </div>
    ) : (
      // Render other components based on the selected tab
      <Outlet />
      
    )}
  </div>
  );
}
