import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";

export const Signup = () => {
  // Form input state variables
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  // UI interaction state variables
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    number: false,
    specialChar: false,
  });
  const [emailValid, setEmailValid] = useState(null); // null indicates no validation yet
  const [showPopup, setShowPopup] = useState(false); // For success popup
  const [emailExistsPopup, setEmailExistsPopup] = useState(false); // Show popup if email exists
  const [loading, setLoading] = useState(false); // For loading states

  const navigate = useNavigate();

  // API URL from environment variables
  const apiUrl = process.env.REACT_APP_API_URL;

  // Regular expressions for validation
  const lengthRegex = /.{8,}/; // Password must be at least 8 characters long
  const digitRegex = /\d/; // At least one digit
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Special characters regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex
  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // Basic international phone number validation




  // Handle password input changes and validate
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Update password validation states
    setPasswordValid({
      length: lengthRegex.test(newPassword),
      number: digitRegex.test(newPassword),
      specialChar: specialCharRegex.test(newPassword),
    });
  };

  // Check if email already exists in the database
  const checkEmailExistence = async (emailToCheck) => {
    try {
      const response = await axios.post(`${apiUrl}/check-email`, { email: emailToCheck });
      return response.data.emailExists;
    } catch (error) {
      console.error("Error checking email:", error.response?.data || error.message);
      // Optionally, handle specific error statuses here
      return false;
    }
  };

  // Handle email input changes and reset related states
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailValid(null); // Reset email validation state
    setEmailExistsPopup(false); // Reset email existence popup
  };

  // Validate email on blur (when the input loses focus)
  const handleEmailBlur = async () => {
    if (email.trim() === "") {
      setEmailValid(false); // Invalid if empty
      setEmailExistsPopup(false);
      return;
    }

    const valid = emailRegex.test(email);
    setEmailValid(valid);

    if (valid) {
      setLoading(true);
      const emailExists = await checkEmailExistence(email);
      setEmailExistsPopup(emailExists);
      setLoading(false);
    } else {
      setEmailExistsPopup(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

   
    if (!emailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    if (emailExistsPopup) {
      alert("This email is already registered.");
      return;
    }

    if (!passwordValid.length || !passwordValid.number || !passwordValid.specialChar) {
      alert(
        "Password must be at least 8 characters long, include at least one number, and contain at least one special character."
      );
      return;
    }

    if (!termsAccepted) {
      alert("Please accept the privacy policy and terms of use.");
      return;
    }

    // Proceed with registration
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/register`, {
    
        email,
        password,
        agreedToTerms: termsAccepted,
      });
      console.log("Registration successful:", response);
      setShowPopup(true); // Show success popup
      setLoading(false);
    } catch (error) {
      console.error("Registration Error:", error.response?.data || error.message);
      alert("Registration failed. Please try again later.");
      setLoading(false);
    }
  };

  // Handle closing the success popup and redirecting to login
  const handlePopupClose = () => {
    setShowPopup(false);
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-purple-500 to-blue-500 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <AiOutlineUserAdd className="text-6xl text-purple-600 mb-2" />
          <h2 className="text-3xl font-semibold text-gray-800">Create your Aisa Account</h2>
        </div>
        <form onSubmit={handleSubmit}>
          {/* Email Address Field */}
          <div className="mb-4 relative">
            <label className="block font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              placeholder="Enter Email Address"
              value={email}
              className={`w-full px-4 py-2 mt-1 border rounded-md ${
                emailValid === null
                  ? "border-gray-300"
                  : emailValid && !emailExistsPopup
                  ? "border-green-500"
                  : "border-red-500"
              } focus:outline-none focus:ring-2 focus:ring-purple-500`}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              required
            />
            {/* Error Messages */}
            {emailValid === false && email.trim() === "" && (
              <p className="text-red-500 text-sm mt-1">Email field cannot be empty!</p>
            )}
            {emailValid === false && email.trim() !== "" && (
              <p className="text-red-500 text-sm mt-1">
                Invalid email format! Ensure it includes "@" and a domain.
              </p>
            )}
            {emailExistsPopup && (
              <p className="text-red-500 text-sm mt-1">Email is already registered!</p>
            )}
            {/* Validation Icons */}
            {emailValid && !emailExistsPopup && (
              <BsCheckCircle className="absolute right-3 top-9 text-green-500" />
            )}
            {emailValid === false && !emailExistsPopup && email.trim() !== "" && (
              <BsXCircle className="absolute right-3 top-9 text-red-500" />
            )}
            {/* Loading Indicator for Email Check */}
            {loading && emailValid && !emailExistsPopup && (
              <span className="absolute right-3 top-9 text-gray-500">Checking...</span>
            )}
          </div>

          {/* Password Field */}
          <div className="mb-4 relative">
            <label className="block font-medium text-gray-700">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              className={`w-full px-4 py-2 mt-1 border rounded-md ${
                passwordValid.length && passwordValid.number && passwordValid.specialChar
                  ? "border-green-500"
                  : password.trim() === ""
                  ? "border-red-500"
                  : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-purple-500`}
              onChange={handlePasswordChange}
              required
            />
            {/* Toggle Password Visibility */}
            <span
              className="absolute right-3 top-9 cursor-pointer text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            {/* Error Messages */}
            {(!passwordValid.length || !passwordValid.number || !passwordValid.specialChar) && password.trim() !== "" && (
              <div className="text-red-500 text-sm mt-1">
                <ul>
                  {!passwordValid.length && <li>• At least 8 characters</li>}
                  {!passwordValid.number && <li>• At least one number</li>}
                  {!passwordValid.specialChar && <li>• At least one special character (e.g., @)</li>}
                </ul>
              </div>
            )}
            {password.trim() === "" && (
              <p className="text-red-500 text-sm mt-1">Password cannot be empty!</p>
            )}
          </div>

          {/* Terms and Conditions */}
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="mr-2"
              required
            />
            <label className="text-gray-700">
              I accept the{" "}
              <Link to="/privacy" className="text-purple-600 underline">
                privacy policy
              </Link>{" "}
              and{" "}
              <Link to="/terms" className="text-purple-600 underline">
                terms of use
              </Link>
              .
            </label>
          </div>

          {/* Register Button */}
          <button
            type="submit"
            className={`w-full py-2 mt-4 text-white rounded-md transition duration-200 ${
              passwordValid.length &&
              passwordValid.number &&
              passwordValid.specialChar &&
              termsAccepted &&
              !emailExistsPopup &&
              emailValid 
                ? "bg-purple-600 hover:bg-purple-700"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={
              !passwordValid.length ||
              !passwordValid.number ||
              !passwordValid.specialChar ||
              !termsAccepted ||
              emailExistsPopup ||
              !emailValid ||
              loading
            }
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>

        {/* Sign In Link */}
        <p className="mt-4 text-center text-gray-600">Already have an account?</p>
        <Link
          to="/login"
          className="block w-full text-center mt-2 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-200"
        >
          Sign In
        </Link>
      </div>

      {/* Success Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[300px] md:w-[400px] max-w-full text-center">
            <h3 className="text-lg font-semibold text-green-500">Registration Successful!</h3>
            <p className="text-gray-600 mt-2">You have been registered successfully.</p>
            <button
              onClick={handlePopupClose}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
